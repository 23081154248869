<template>
  <div id="app">
    <router-view/>
    <Popup />
  </div>
</template>
<script>
import Popup from '@/components/popup'
export default {
  components: {
    Popup
  },
  created() {
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) 
    {
      //手机端口地址
      location.href = "https://united-advance.com/mobile/#/"
    } else {
      //pc端口地址
      location.href = "https://united-advance.com/pc/#/"
    }
  }
}
</script>
<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Source Han Sans CN";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
