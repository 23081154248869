<template>
  <div>
    <div class="level7">
      <div class="level7_inner">
        <div class="nav_top">
          <div class="left" @click="$router.push('/')">
            <img class="left_img" src="@/assets/images/4.png">
          </div>
          <ul class="right">
            <li class="item" @click="$route.path !== '/hy' && $router.push('/hy')"> 走进合远 </li>
            <li class="line"></li>
            <li class="item" @click="$route.path !== '/dynamic' && $router.push('/dynamic')"> 合远动态 </li>
            <li class="line"></li>
            <li class="item" @click="$route.path !== '/joinHy' && $router.push('/joinHy')"> 加入合远 </li>
            <li class="line"></li>
            <li class="item" @click="$route.path !== '/connect' && $router.push('/connect')"> 联系我们 </li>
            <li class="line"></li>
            <li class="item" @click="jumpToCBS"> 投资者服务 </li>
          </ul>
        </div>
        <div class="tipInfo">
          <div class="tipInfo_left">
            <p class="tipInfo_content">本网站所载全部资料的版权及其他任何权利均为上海合远私募基金管理有限公司所有，未经许可不得使用，不得转载、转播、摘编。</p>
            <p class="tipInfo_content" style="cursor: pointer;" @click="jumpTo">Copyright © 上海合远私募基金管理有限公司版权所有 沪ICP备2022014598号</p>
          </div>
          <div class="tipInfo_center">
            <p class="tipInfo_content">电话：021-68585855</p>
            <p class="tipInfo_content">邮箱：service@united-advance.com </p>
            <p class="tipInfo_content">地址：上海市浦东新区杨高南路759号陆家嘴世纪金融广场2号楼1902</p>
          </div>
          <div class="tipInfo_right">
            <img class="qrcode" src="@/assets/images/qrcode.png" alt="">
            <p class="tipInfo_content" style="text-align: center;">
              关注合远基金<br />微信公众号
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{}
    },
    mounted(){},
    methods:{
      jumpToCBS() {
        window.open('https://service.united-advance.com.cn/ztcbsweb/investorservice/pc/login.htm?authStr=61705562524D3265442B56524749745A787936537A577A5841494D374E792B4E65356D4172664B4B4D59593D')
      },
      jumpTo() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
    },
  }
</script>
<style scoped lang="less">
.level7{
    height: 394px;
    background: rgba(181,181,182, .95);
    margin-top: -394px;
    .level7_inner{
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      .nav_top{
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        .left{
          width: 350px;
          .left_img{
            width:100%;
            object-fit: contain;
            cursor: pointer;
          }
        }
        .right{
          display: flex;
          align-items:center;
          font-size: 15px;
          color: rgb(255 255 255);
          .item{
            cursor: pointer;
            transition: .3s;
          }
          .item:hover{
            color: #325072  ;
          }
          .line{
            width: 1.5px;
            height: 12px;
            margin: 0 25px;
            background-color: rgb(255 255 255);
          }
        }
      }
      .tipInfo{
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        font-size: 13px;
        margin-top: 38px;
        .tipInfo_left{
          width: 500px;
        }
        .tipInfo_content{
          margin-bottom: 10px;
          line-height: 34px;
        }
        .tipInfo_center{
          width: 400px;
        }
        .tipInfo_right{
          width: 140px;
          .qrcode{
            width: 130px;
            height: 130px;
            margin-bottom: 15px;
            background: #eee;
          }
        }
      }
    }
  }
</style>