<template>
  <div>
    <el-dialog
      title="重要声明"
      :visible.sync="$store.state.diaShow"
      width="60%"
      top="4vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center>
      <div class="content">
        投资者提示
        <br /><br />在继续浏览本网站前，敬请您仔细阅读本重要提示，并将页面滚动至本页结尾“同意并接受”或“不同意”，根据您的具体情况选择继续浏览或放弃。点击“同意并接受”，视为您已经阅读并认定自己符合合格投资者条件，且愿意遵守本提示内容。如您不同意任何有关条款，请点击"不同意"键。
        <br /><br />一、私募基金合格投资者认定标准
        <br /><br />1、根据《中华人民共和国证券投资基金法》、《私募投资基金监督管理暂行办法》等法律法规规定，私募基金的合格投资者应为具备相应风险识别能力和风险承担能力、投资于单只私募基金产品的金额不低于100万元人民币且符合下列相关标准的单位和个人：
        <br /><br />（1）净资产不低于1000 万元的单位；
        <br /><br />（2）金融资产不低于300 万元或者最近三年个人年均收入不低于50 万元的个人。
        前款所称金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。
        <br /><br />2、下列投资者视为合格投资者：
        <br /><br />（一）社会保障基金、企业年金等养老基金，慈善基金等社会公益基金；
        <br /><br />（二）依法设立并在基金业协会备案的投资计划；
        <br /><br />（三）投资于所管理私募基金的私募基金管理人及其从业人员；
        <br /><br />（四）中国证监会规定的其他投资者。
        <br /><br />二、市场有风险，投资需谨慎。投资者购买基金，既可能按其持有的份额享受基金投资产生的收益，也可能承担基金投资所带来的损失。管理人过往的业绩数据并不预示其未来的表现，投资者不应依赖本网站所提供的数据做出投资决策。
        <br /><br />三、本网站所载的各种信息和数据等仅供参考，并不构成广告或销售要约、不构成任何形式的投资建议。投资者应仔细审阅相关基金产品的合同等文件以了解其风险因素，并自行承担所作出投资决策之相应风险。
        <br /><br />四、本网站所载的各种信息和数据等是我们认为合法或已公开的信息，但仅代表本公司于发布当时的分析与判断，可能在您阅读时资料的准确性或完整性已发生变化。本公司网站部分信息内容来源于第三方，但我们不对第三方所提供之有关资料的准确性、充足性或完整性作出任何保证。公司及雇员不对于本网站内第三方所提供之资料的任何错误或遗漏负任何法律责任，敬请投资者审慎鉴别、判断。
        <br /><br />五、本网站所刊载的所有公开资料上海合远私募基金管理有限公司可予以更改或修订而毋须前事通知，上海合远私募基金管理有限公司并不承诺定期更新本网页。
        <br /><br />六、本网站知识产权属于上海合远私募基金管理有限公司。未经本公司书面许可，您不得复印、复制或再转发本网站资料的全部或其任何部分。对侵犯本公司合法利益之行为，本公司保留追究相关方责任的权利。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuse">不同意</el-button>
        <el-button type="primary" @click="agree">同意并接受</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data(){
      return{}
    },
    mounted(){},
    methods:{
      agree() {
        this.$store.commit('setDiaShow', false)
      },
      refuse() {
        window.open('')
      }
    },
  }
</script>
<style scoped lang="less">
.content{
  height:67vh;
  overflow-y: scroll;
  line-height: 25px;
}
</style>