<template>
  <div class="home">
    <TopNav />
    <div class="level1">
      <img style="width: 100%" src="@/assets/images/1.png" alt="">
      <div class="level1_inner">
        <div class="level1_center">
          <div class="left">
            <img class="left_img" src="@/assets/images/3.png">
          </div>
          <div class="right">
            <img class="right_img" src="@/assets/images/2.png">
            <img class="right_img" src="@/assets/images/5.png">
          </div>
        </div>
      </div>
    </div>
    <div class="level2">
      <div class="level2_inner">
        <div class="title_wrap">
          <span class="line"></span>
          <span class="title">客户为先，成长共享</span>
          <span class="line"></span>
        </div>
        <div class="content">
          <div class="item">
            <p class="item_title">与持有人成长共享</p>
            <p class="item_content">
              公司第一目标是全力做好投研，
              良好的净值曲线是资管公司赖以生存发展的根本。
              我们希望持有人能够长期陪伴我们成长，
              并在陪伴的过程中获得扎扎实实的收益。
            </p>
          </div>  
          <div class="item center">
            <p class="item_title">公司内部成长共享</p>
            <p class="item_content">
              建立扁平、高效、公平的管理和分配机制，
              让大家有收获、有成长、心情愉悦，
              充分实现公司和员工的和谐发展。
            </p>
          </div>
          <div class="item">
            <p class="item_title">与外部成长共享</p>
            <p class="item_content">
              我们将和合作机构相互扶持，共同成长；
              同时积极承担相应的社会责任，
              为资管行业健康发展尽一份绵薄之力。
            </p>
          </div>
        </div>
        <!-- <div class="top">
          <p class="top_title">公司管理</p>
          <p class="top_line">
            <span class="top_line1"></span>
            <span class="top_line2"></span>
          </p>
        </div>
        <p class="mng">
          我们以扁平化、尊重专业、平等沟通的方式开展工作，提升效率、相互支持、紧密合作。
        </p> -->
      </div>
    </div>
    <div class="level3">
      <div class="level3_inner">
        <div class="level3_left">
          <img class="left_img" src="@/assets/images/8.png" alt="">
        </div>
        <div class="level3_right">
          <div class="top">
            <p class="top_title">投资理念：成长价值</p>
            <p class="top_line">
              <span class="top_line1"></span>
              <span class="top_line2"></span>
            </p>
          </div>
          <div v-show="contentActive === 1" class="center">
            <p class="center_title">价值投资</p>
            <p class="center_content">
              是靠企业价值创造，
              以真实利润支撑市值增长获利。
              在企业成长壮大的过程中，
              一方赚钱并不以其它参与方亏钱为代价，
              所有参与者都能获利，是多赢的思路。
              这与合远追求“成长与共享”的多赢理念不谋而合。
            </p>
          </div>
          <div v-show="contentActive === 2" class="center">
            <p class="center_title">根植时代</p>
            <p class="center_content">
              投资组合的构建要和经济所处阶段以及时代发展特征相吻合。
              根据目前中国经济发展的特点，
              奉行以合理的价格买入成长性企业，
              同时与时俱进，深刻体察社会和产业的变化。
              “躺赢”是不可能成功的。
            </p>
          </div>
          <div v-show="contentActive === 3" class="center">
            <p class="center_title">精选成长</p>
            <p class="center_content">
              首先是通过前瞻分析和行业比较找准未来一个阶段的优势景气子行业，
              充分借助行业上升的推力；其次是通过深入研究紧密跟踪，
              仔细甄别“真成长”和“伪成长”，选出未来优质的龙头企业，
              合理把握其估值准绳，通过陪伴企业快速、高质量的价值创造过程，
              分享其市值扩张成果。而不是盲目去“押注“，单纯追逐市场热点。
            </p>
          </div>
          <div class="bottom">
            <img class="arrow" v-show="contentActive === 1" @click="pre1" src="@/assets/images/11.png" alt="">
            <img class="arrow" v-show="contentActive > 1" @click="pre1" src="@/assets/images/18.png" alt="">
            <img class="arrow" v-show="contentActive < 3" @click="next1" style="margin-left: 20px" src="@/assets/images/10.png" alt="">
            <img class="arrow" v-show="contentActive === 3" @click="next1" style="margin-left: 20px" src="@/assets/images/17.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="level4">
      <p class="content1">科学严谨的投研框架</p>
      <p class="content2">“自上而下”和“自下而上”相结合</p>
      <div class="box_wrap">
        <div class="box">
          <div class="box_top">
            <span class="big">10</span>
            <span class="small">%</span>
          </div>
          <div class="box_bottom">
            宏观判断
          </div>
          <div class="layer">
            <p class="name">10%</p>
            <p class="detail one">
              从更高维度、更长时间上去考虑人口结构、社会发展特征，行业兴衰等底层变化。
            </p>
          </div>
        </div>
        <div class="box center">
          <div class="box_top">
            <span class="big">20</span>
            <span class="small">%</span>
          </div>
          <div class="box_bottom">
            行业比较
          </div>
          <div class="layer">
            <p class="name">20%</p>
            <p class="detail one">
              基于产业的视角分析行业变化。紧密跟踪时代产业变化，选择高质量成长子行业。
            </p>
          </div>
        </div>
        <div class="box">
          <div class="box_top">
            <span class="big">70</span>
            <span class="small">%</span>
          </div>
          <div class="box_bottom">
            个股选择
          </div>
          <div class="layer">
            <p class="name">30%</p>
            <p class="detail one">
              围绕未来1~2年景气度好的龙头企业展开研究。
            </p>
          </div>
        </div>
      </div>
      <p class="content2 link">
        <span class="more" @click="$router.push('/hy?id=Cl')">了解更多详情</span>
      </p>
    </div>
    <div class="level5">
      <div class="level5_inner">
        <div class="level5_left">
          <div class="top">
            <p class="top_title">金牛班底 携手同行</p>
          </div>
          <div class="center">
            <!-- <p class="center_title">12名金牛团队成员</p> -->
            <p class="center_content">
              长期共事，共同穿越多轮牛熊转换
            </p>
            <p class="center_content">
              来自投资、研究、市场、运营、合规、交易各部门
            </p>
            <p class="center_content">
              曾获得行业各大奖项
            </p>
          </div>
        </div>
        <div class="level5_right">
          <div class="top">
            <p v-show="awardActive === 0" class="top_title">2022-23</p>
            <p v-show="awardActive === 1" class="top_title">2021</p>
            <p v-show="awardActive === 2" class="top_title">2020</p>
            <p v-show="awardActive === 3" class="top_title">2019</p>
            <p v-show="awardActive === 4" class="top_title">2016</p>
            <p v-show="awardActive === 5" class="top_title">公募期间</p>
          </div>
          <div class="award">
            <img class="award_img" src="@/assets/images/13.png" alt="">
            <span style="vertical-align: bottom">奖项</span>
          </div>
          <div class="line"></div>
          <div class="award_wrap" v-show="awardActive === 0">
            <div class="award_des">
              兴业银行私人银行优秀合作机构
            </div>
            <div class="award_des">
              中国银河证券2022年投资者信赖伙伴
            </div>
            <div class="award_des">
              好买财富2022年度中国好私募“新白马基金”奖
            </div>
            <div class="award_des">
              格上财富金樟奖-2022年度最值得信赖的私募机构
            </div>
            <div class="award_des">
              第七届金斧奖-成长潜力奖
            </div>
          </div>
          <div class="award_wrap" v-show="awardActive === 1">
            <div class="award_des">
              《中国证券报》五年期金牛私募投资经理（股票策略）
            </div>
            <div class="award_des">
              《中国基金报》英华奖五年期最佳私募投资经理
            </div>
            <div class="award_des">
              《上海证券报》第十二届金阳光 · 五年卓越私募基金经理奖
            </div>
          </div>
          <div class="award_wrap" v-show="awardActive === 2">
            <div class="award_des">
              《中国证券报》一年期金牛私募投资经理
            </div>
            <div class="award_des">
              《证券时报》金长江奖  同创2期 ·  绝对回报私募基金产品（五年期）
            </div>
            <div class="award_des">
              中国证券私募华曜奖年度领军人物
            </div>
            <div class="award_des">
              兴业银行私人银行钻石级投资人
            </div>
            <div class="award_des">
              Wind  同望1期1号 · 五年期最强私募基金（股票多头）
            </div>
            <div class="award_des">
              Wind  同望1期1号 · 五年期最强私募基金
            </div>
            <div class="award_des">
              格上财富 金樟奖 · 最佳股票私募基金经理
            </div>
          </div>
          <div class="award_wrap" v-show="awardActive === 3">
            <div class="award_des">
              格上财富 金樟奖 · 最佳股票私募基金经理
            </div>
            <div class="award_des">
              《证券时报》金长江奖  年度优秀私募基金经理
            </div>
          </div>
          <div class="award_wrap" v-show="awardActive === 4">
            <div class="award_des">
              格上财富 同望1期 · 最佳股票策略私募基金
            </div>
          </div>
          <div class="award_wrap" v-show="awardActive === 5">
            <div class="award_des">
              2014年《中国证券报》五年期开放式股票型持续优胜金牛基金
            </div>
            <div class="award_des">
              2013年《中国证券报》五年期开放式股票型持续优胜金牛基金
            </div>
            <div class="award_des">
              2012年《中国证券报》五年期开放式股票型持续优胜金牛基金
            </div>
            <div class="award_des">
              2011年《中国证券报》三年期开放式股票型持续优胜金牛基金
            </div>
            <div class="award_des">
              2013年《证券时报》五年持续回报股票型明星基金奖
            </div>
            <div class="award_des">
              2012年《证券时报》五年持续回报股票型明星基金奖
            </div>
            <div class="award_des">
              2012年《证券时报》年度股票型明星基金奖
            </div>
          </div>
          <div class="bottom">
            <img class="arrow" v-show="awardActive === 0" @click="pre2" src="@/assets/images/11.png" alt="">
            <img class="arrow" v-show="awardActive >= 1" @click="pre2" src="@/assets/images/16.png" alt="">
            <img class="arrow" v-show="awardActive < 5" @click="next2" style="margin-left: 20px" src="@/assets/images/14.png" alt="">
            <img class="arrow" v-show="awardActive === 5" @click="next2" style="margin-left: 20px;" src="@/assets/images/17.png" alt="">
            
            <!-- <img class="arrow" @click="pre2" src="@/assets/images/11.png" alt="">
            <img class="arrow" @click="next2" style="margin-left: 20px" src="@/assets/images/14.png" alt=""> -->
          </div>
        </div>
      </div>
    </div>
    <div class="level6">
      <div class="title_wrap">
        <span class="line"></span>
        <span class="title">做值得长期托付的精品资产管理公司</span>
        <span class="line"></span>
      </div>
    </div>
    <Bottom />

    <Float />

  </div>
</template>

<script>
import TopNav from '@/components/topNav'
import Bottom from '@/components/bottom'
import Float from '@/components/float.vue'
export default {
  name: 'HomeView',
  components: {
    TopNav,
    Bottom,
    Float
  },
  data() {
    return {
      contentActive: 1,
      awardActive: 0
    }
  },
  methods: {
    pre1() {
      if(this.contentActive === 0) {
        return false
      }
      this.contentActive -= 1
    },
    pre2() {
      if(this.awardActive === 0) {
        return false
      }
      this.awardActive -= 1
    },
    next1() {
      if(this.contentActive === 5) {
        return false
      }
      this.contentActive += 1
    },
    next2() {
      if(this.awardActive === 5) {
        return false
      }
      this.awardActive += 1
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandle)
  }
}
</script>
<style lang="less" scoped>
  
  .level1{
    position: relative;
    height: 920px;
    // background-image: url('../../assets/images/1.png');
    // background-size: contain;
    // background-repeat: no-repeat;
    .level1_inner{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      .level1_center{
        display: flex;
        // justify-content: center;
        align-items: center;
        margin-top: 200px;
        .left{
          width: 595px;
          margin-left: 150px;
          .left_img{
            width: 100%;
          }
        }
        .right{
          width: 524px;
          margin-top: 50px;
          .right_img{
            width:100%;
          }
        }
      }
    }
  }
  .level2{
    height: 800px;
    background-image: url('../../assets/images/6.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    .level2_inner{
      width: 1200px;
      margin: auto;
      overflow: hidden;
      .title_wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 160px;
        .line{
          width: 168px;
          height: 2px;
          background-color: #325072;
          border-radius: 20%;
        }
        .title{
          font-size: 40px;
          font-weight: bold;
          color: #325072;
          margin: 0 20px;
        }
      }
      .content{
        display: flex;
        justify-content: center;
        margin-top: 91px;
        .item{
          width: 270px;
          height: 310px;
          background: #EAEAEA;
          border-radius: 48px;
          padding: 0 35px;
          box-sizing: border-box;
          color: #707070;
          transition: .3s;
          &:hover{
            color: #5086B9;
          }
          &.center{
            margin: 0 75px;
          }
          .item_title{
            font-size: 25px;
            font-weight: bold;
            margin-top:40px;
          }
          .item_content{
            margin-top:30px;
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
      .top{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 248px;
        .top_title{
          font-size: 40px;
          color: #325072;
          font-weight: bold;
        }
        .top_line{
          display: flex;
          margin-top: 20px;
          .top_line1{
            width: 40px;
            height: 3px;
            background-color: #E5A200;
          }
          .top_line2{
            width: 115px;
            height: 3px;
            background-color: #325072;
          }
        }
      }
      .mng{
        font-size: 25px;
        color: #325072;
        margin-top: 55px;
        text-align: center;
      }
    }
  }
  .level3{
    height: 812px;
    background-image: url('../../assets/images/7.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    .level3_inner{
      width: 1200px;
      height: 100%;
      margin: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      .level3_left{
        width: 500px;
        // margin-top: 300px;
        .left_img{
          width: 100%;
        }
      }
      .level3_right{
        margin-left: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // margin-top: 300px;
        .top{
          .top_title{
            font-size: 40px;
            color: #325072;
          }
          .top_line{
            display: flex;
            .top_line1{
              width: 45px;
              height: 3px;
              background-color: #E5A200;
            }
            .top_line2{
              width: 110px;
              height: 3px;
              background-color: #325072;
            }
          }
        } 
        .center{
          margin-top: 15px;
          width: 460px;
          .center_title{
            font-size: 20px;
            color: rgb(50 80 114);
          }
          .center_content{
            font-size: 18px;
            color: rgb(50 80 114);
            line-height: 40px;
            margin-top:20px;
          }
        }
        .bottom{
          margin-top: 30px;
          .arrow{
            cursor: pointer;
            width: 25px;
            height: 15px;
          }
        }
      }
    }
  }
  .level4{
    height: 686px;
    background: #5086B9;
    overflow: hidden;
    color: #FFFFFF;
    .content1{
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      margin-top:159px;
    }
    .content2{
      font-size: 25px;
      text-align: center;
      margin-top:30px;
      &.link{
        .more{
          font-size: 20px;
          color: #f4f4f4;
          margin-top: 10px;
          border-bottom: 1px solid #f4f4f4;
          cursor: pointer;
        }
      }
    }
    .box_wrap{
      margin-top:73px;
      display: flex;
      justify-content: center;
      .box{
        position: relative;
        width: 310px;
        height: 160px;
        background: #7FA3C6;
        border-radius: 39px;
        transition: .5s;
        font-size: 16px;
        cursor: pointer;
        &.center{
          margin: 0 93px;
        }
        // &:hover{
        //   background-color: #325072;
        // }
        .box_top{
          display: flex;
          justify-content: center;
          margin-top:26px;
          .big{
            font-size: 52px;
          }
          .small{
            margin-top: 35px;
            font-size: 16px;
            padding-left: 2px;
          }
        }
        .box_bottom{
          margin-top: 4px;
          text-align: center;
        }
        &:hover .layer{
          opacity: 1;
        }
        .layer{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #325072;
          border-radius: 39px;
          color: #fff;
          text-align: center;
          opacity: 0;
          transition: opacity .3s;
          .name{
            font-size: 30px;
            margin-top:10px;
          }
          .detail{
            width: 251px;
            margin: 10px auto 0;
            font-size: 14px;
            line-height: 28px;
            &.one{
              width: 208px;
            }
          }
        }
      }
    }
  }
  .level5{
    height: 803px;
    background-image: url('../../assets/images/12.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    .level5_inner{
      width: 1200px;
      height: 100%;
      margin: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      .level5_left{
        // margin-top: 273px;
        .top{
          .top_title{
            font-size: 40px;
            color: #325072;
          }
        }
        .center{
          margin-top: 28px;
          width: 460px;
          .center_title{
            font-size: 25px;
            color: rgb(50 80 114);
            margin-bottom: 18px;
          }
          .center_content{
            font-size: 18px;
            color: rgb(50 80 114);
            margin-top:10px;
          }
        }
      }
      .level5_right{
        margin-left: 270px;
        display: flex;
        flex-direction: column;
        height: 380px;
        color: #fff;
        .top{
          margin-top: 25px;
          .top_title{
            font-size: 40px;
          }
        } 
        .award{
          font-size: 30px;
          margin-top: 15px;
          .award_img{
            width: 44px;
            height: 50px;
            margin-right: 11px;
          }
        }
        .line{
          width: 298px;
          height: 2px;
          background-color: #E5A200;
          margin: 28px 0 18px;
        }
        .award_wrap{
          height: 90px;
          overflow-y: scroll;
        }
        .award_des{
          position: relative;
          width: 470px;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 30px;
          text-indent: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .award_des::after{
          content: '';
          position: absolute;
          left: 0;
          top: 13px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #fff;
        }
        .bottom{
          margin-top: 30px;
          .arrow{
            cursor: pointer;
            width: 25px;
            height: 15px;
          }
        }
      }
    }
  }
  .level6{
    height: 1080px;
    background-image: url('../../assets/images/15.png');
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    .title_wrap{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 208px;
      .line{
        width: 168px;
        height: 2px;
        background-color: #325072;
        border-radius: 20%;
      }
      .title{
        font-size: 30px;
        font-weight: bold;
        color: #325072;
        margin: 0 20px;
      }
    }
  }
  .fixed-wrap{
    position: fixed;
    bottom: 120px;
    left: 20px;
    background: rgba(0, 0, 0, .1);
    padding: 30px 20px;
    color: #000;
    font-weight: bolder;
  }
  
</style>
